import React from "react";
import { Link } from "gatsby";
import { logger } from "../logging/logger";

export const Footer = () => {
  return (
    <div className="footer fixed-bottom bg-dark p-3 mt-3" style={{ fontFamily: "Sans Serif" }}>
      <div className="footer-inner row text-center">
        <a
          className="col-sm-4 text-white "
          href={"mailto:mark@procedural.build"} target="_blank" rel="noreferrer"
                   onClick={() => {
           logger.info(`Click Contact Us - Footer`);
           return true;
         }}
        >
          Contact Us
        </a>
        <Link className="col-sm-4 text-white" to="/docs/" target="_blank"
                 onClick={() => {
           logger.info(`Click Docs - Footer`);
           return true;
         }}
        >
          Documentation
        </Link>
        <a
          className="col-sm-4 text-white "
          href={"https://discourse.mcneel.com/c/plug-ins/procedural-compute/154"} target="_blank" rel="noreferrer"
                   onClick={() => {
           logger.info(`Click Forum - Footer`);
           return true;
         }}
        >
          Visit Forum
        </a>
      </div>
    </div>
  );
};
